body {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(135deg, #f3f4f6, #e5e7eb);
    margin: 0;
    padding: 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; /* Distribute space between the two divs */
    min-height: 100vh; /* Full height of the viewport */
    padding: 10px 20px;
    text-align: center;
  }
  
  .div {
    margin-bottom: 20px; /* Optional: Adds extra space between divs */
  }
  

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;          /* Align logo and title vertically */
    justify-content: center;      /* Center the content */
    margin-bottom: 0;          /* Space below the title and logo */
  }
  
  .logo {
    max-width: 150px;      /* Adjust logo size */
    transition: transform 0.6s ease;  /* Transition effect for smooth animation */
    margin-bottom: 0;   /* Space below the logo */
  }

  .ratings {
    max-width: 300px;      /* Adjust logo size */
    transition: transform 0.6s ease;  /* Transition effect for smooth animation */
    margin-bottom: 0;   
  }
  
  .logo:hover {
    transform: rotate(360deg);  /* Rotate 360 degrees on hover */
  }
  
  h1 {
    font-size: 48px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0;  /* Remove space below the title */
  }
  
  p {
    font-size: 18px;
    color: #666;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input {
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 300px;
    outline: none; /* Remove the default blue outline */
  }
  
  .input:focus {
    border: 1px solid black; /* Add a black border on focus */
    outline: none; /* Remove the default blue outline */
  }
  
  .btn {
    background-color: #333;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;  /* Remove the default blue outline */
  }
  
  .btn:focus {
    outline: 2px solid black;  /* Add a black outline on focus */
  }
  
  .btn:hover {
    background-color: #666;
  }
  